import Column from '@/Components/Grid/Column';
import Container from '@/Components/Grid/Container';
import Row from '@/Components/Grid/Row';
import { PageProps } from '@/types';
import { Head, Link } from '@inertiajs/react';

import HeroImageMobile from '@static/splash-hero-mobile.png?w=1536;1024;767;375&format=webp&quality=85&as=srcset&imagetools';
import HeroImageWebp from '@static/splash-hero.png?w=3600;2560;1920;1500;991&format=webp&quality=85&as=srcset&imagetools';



import Modal from '@/Components/Modal';
import AccordionItem from '@/Components/Pages/Splash/AccordionItem';
import VideoPlayer from '@/Components/VideoPlayer';
import useMediaQuery from '@/Hooks/useMediaQuery';
import { PodcastModel } from '@/types/models/PodcastModel';
import { VideoModel } from '@/types/models/VideoModel';


import { useState } from 'react';

const Index = ({ auth, movies, podcasts }: PageProps & {
    movies: VideoModel[],
    podcasts: PodcastModel[];
}) => {
    const isMobile = useMediaQuery();

    const [isModalShown, setIsModalShown] = useState<boolean>(false);
    const [modalData, setModalData] = useState<any>('video');
    const [currentVideo, setCurrentVideo] = useState<VideoModel>();
    const [currentPodcast, setCurrentPodcast] = useState<PodcastModel>();

    const showModal = (type: string) => {
        setModalData(type);
        setIsModalShown(true);
    }

    const closeModal = () => {
        setIsModalShown(false);
        setTimeout(() => {
            setModalData(null);
        }, 300)
    }

    const showClickedVideo = (video: VideoModel) => {
        showModal('video');
        setCurrentVideo(video);
    }

    const showClickedPodcast = (podcast: PodcastModel) => {
        showModal('podcast');
        setCurrentPodcast(podcast);
    }

    return (
        <>
            <Head title="Strona główna" />

            <Modal show={isModalShown} onClose={closeModal}>
                {modalData == 'video' &&
                    <p className='text-center text-midnightAbyss h3 font-semibold tracking-[0.64px] mb-4'>{currentVideo?.name}</p>
                }
                {modalData == 'podcast' &&
                    <p className='text-center text-midnightAbyss h3 font-semibold tracking-[0.64px] mb-4'>{currentPodcast?.name}</p>
                }
                <p className='text-center text-midnightAbyss text-body tracking-[0.24px] mb-10'>Oglądasz krótki fragment materiału. Aby uzyskać pełen dostęp i korzystać z naszych treści, zapraszamy do rejestracji.</p>
                <div className="w-full aspect-video player-wrapper relative mb-10 rounded-lg overflow-hidden [&>div>video]:object-cover maxMd:-mx-8 maxMd:w-[calc(100vw_-_32px)]">
                    <VideoPlayer
                        playing
                        controls
                        muted
                        url={modalData === 'video' ? currentVideo?.video : currentPodcast?.audio}
                        className='absolute inset-0 rounded-lg'
                        width={`100%`}
                        height={`100%`}
                    />
                </div>
                <p className='text-center text-midnightAbyss text-body tracking-[0.24px] mb-8'>
                    Zainteresował Cię ten materiał i chciałbyś kontynuować oglądanie? Dołącz do serwisu edukacyjnego juź dziś. Rejestracja zajmie Ci maksymalnie <span className='text-accordorange'>15 minut.</span>
                </p>
                <div className='text-center'>
                    <Link href={route('register')} className='main-btn inline-block max-w-[176px]' title='Zarejestruj się'>
                        Zarejestruj się
                    </Link>
                </div>
            </Modal>

            <div className="min-h-screen bg-gradientMain relative flex flex-col justify-center">
                <header className='maxMd:px-4 maxMd:pt-6 maxMd:pb-4 px-14 flex justify-between z-20 relative py-6 items-center maxMd:mb-6 mb-18'>
                    <Link href='/' title='Strona główna'>
                    <p className='text-midnightAbyss text-3xl font-bold tracking-widest'>LOGO</p>
                    </Link>
                    <div>
                        <Link href={route('login')} className='outline-btn border-accordorange border-1'>
                            Zaloguj się
                        </Link>
                    </div>
                </header>

                <main className='relative z-20'>
                    <Container>
                        <div className="flex flex-col">
                            <Row className='justify-center maxMd:order-2 maxMd:-mt-[35%] relative z-10'>
                                <Column xl={10}>
                                    <h1 className='text-midnightAbyss h1 font-semibold text-center mb-6'>Lorem ipsum dolor sit amet.</h1>
                                </Column>
                            </Row>
                            <Row className='justify-center maxMd:order-3'>
                                <Column xl={6} className='text-center'>
                                    <p className='text-midnightAbyss text-body mb-6'>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi excepturi placeat sed a ducimus cumque omnis neque repellat magnam est aperiam labore ad, deserunt ex deleniti rem eum, quidem consequuntur!
                                    </p>
                                    <Link href={route('register')} className='p-5 maxMd:py-3 maxMd:px-6 maxMd:w-full maxMd:mb-6 text-arcticMist bg-electricBlue rounded-full text-h5 leading-8 font-semibold tracking-[0.36px] inline-block transition-theme hover:bg-transparent border-electricBlue border-2 hover:text-electricBlue'>
                                        Zarejestruj się
                                    </Link>
                                </Column>
                            </Row>
                            {!isMobile &&
                                <div className='pb-[50%] relative maxMd:mb-0 mb-38'>
                                    <img srcSet={`https://picsum.photos/id/119/1800/900?grayscale`} alt="Okładki hero" className='object-contain max-w-full w-full h-full absolute mt-10 left-0 -z-10' />
                                </div>
                            }
                            {isMobile &&
                                <div className='relative maxMd:mb-0 mb-38 order-1'>
                                    <img srcSet={`https://picsum.photos/id/119/1800/900?grayscale`} alt="Okładki hero" className='' />
                                </div>
                            }
                        </div>
                        {/* <Row className='justify-center'>
                            <Column xl={6} className='text-center'>
                                <h2 className='text-midnightAbyss h2 font-semibold maxMd:mb-4 mb-6'>Ostatnio dodane materiały</h2>
                                <p className='text-midnightAbyss text-body maxMd:mb-8 mb-10'>Zobacz co możesz zyskać zapisując się do Accord Academy.</p>
                            </Column>
                        </Row>
                        <Row className='justify-center mb-30 maxMd:mb-16'>
                            <Column xl={10}>
                                <Row>
                                    {movies && movies.map((movie, i) => (
                                        <Column xl={4} md={6} lg={4} className='mb-8' key={i}>
                                            <button type='button' className='w-full text-left' onClick={() => showClickedVideo(movie)}>
                                                <div className="relative w-full pb-[56.25%] aspect-video">
                                                    <div className="absolute inset-0 bg-cover bg-no-repeat rounded-lg" style={{ backgroundImage: `url(${resolveOptimizedImage(movie.hero)})` }}></div>
                                                </div>
                                                <p className='text-midnightAbyss h4 font-semibold mt-2'>{movie.name}</p>
                                            </button>
                                        </Column>
                                    ))}
                                </Row>
                            </Column>
                        </Row> */}
                        <Row className='justify-center mb-38 maxMd:mb-24'>
                            <Column xl={10}>
                                <Row>
                                    <Column xl={6} lg={6} md={6} className='maxMd:order-2'>
                                        {/* <img srcSet={PromotionPodcast} alt="Promocja podkast" /> */}
                                        <img src="https://picsum.photos/id/119/780/620" alt="" />
                                    </Column>
                                    <Column xl={6} lg={6} md={6} className='self-center maxMd:order-1'>
                                        <div className="pl-4 pr-32 md:pr-16 maxMd:px-0">
                                            <h3 className='h2 text-midnightAbyss mb-6 font-semibold maxMd:text-center'>Lorem, ipsum dolor.</h3>
                                            <p className='text-midnightAbyss text-body mb-4 maxMd:text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti, accusantium a reprehenderit eligendi similique nulla.</p>
                                            <ul className='text-midnightAbyss text-body mb-4 list-disc pl-4 maxMd:w-[55%] maxMd:mx-auto maxMd:mb-10'>
                                                <li>
                                                    Lorem, ipsum.
                                                </li>
                                                <li>
                                                    Lorem, ipsum.
                                                </li>
                                                <li>
                                                    Lorem, ipsum.
                                                </li>
                                            </ul>
                                        </div>
                                    </Column>
                                </Row>
                            </Column>
                        </Row>
                        {/* <Row className='justify-center mb-34 maxMd:mb-24'>
                            <Column xl={8} className='text-center'>
                                <h4 className='h2 text-midnightAbyss font-semibold mb-6'>Podcasty dla specjalistów</h4>
                                <p className='text-midnightAbyss h4 mb-14 maxMd:mb-10'>Odkryj nasze podcasty i poszerz swoje horyzonty pozostawiając swobodne ręce.</p>
                            </Column>
                            <Column xl={10}>
                                <div className='flex gap-x-6 maxMd:gap-x-4 maxMd:gap-y-4 flex-wrap justify-center'>
                                    {podcasts && podcasts.map((podcast, i) => (
                                        <button type='button' onClick={() => showClickedPodcast(podcast)} className={`max-w-[240px] w-full ${(i === 0 || i === 1) ? 'maxMd:max-w-[45%]' : 'maxMd:max-w-[29%]'}`} key={i}>
                                            <img src={podcast.hero} alt={podcast.name} className='w-full aspect-square rounded-xl'/>
                                            <p className='text-midnightAbyss h4 maxMd:text-base maxMd:text-left tracking-[0.48px] font-semibold mt-2'>{podcast.name}</p>
                                        </button>
                                    ))}
                                </div>
                            </Column>
                        </Row> */}
                        <Row className='justify-center mb-44 maxMd:mb-24'>
                            <Column xl={8} className='text-center'>
                                <h5 className='h2 text-midnightAbyss font-semibold mb-6'>Lorem ipsum dolor sit amet.</h5>
                                <p className='text-midnightAbyss text-desktop mb-14 maxMd:mb-10'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Exercitationem sit porro obcaecati facilis! Cum corrupti sit, libero labore quae quam.</p>
                                {/* {!isMobile &&
                                    <div className="flex md:flex-wrap sm:flex-wrap lg:flex-nowrap justify-center gap-y-10 gap-x-20">
                                        <div className="group w-1/3 h-full bg-cover bg-no-repeat relative">
                                            <div className="absolute inset-0 transition-theme opacity-0 group-hover:opacity-100">
                                                <img src={TvOn} alt="TV On" className="w-full h-[240px] object-contain" />
                                            </div>
                                            <img src={TvOff} alt="TV Off" className="w-full h-[240px] object-contain" />
                                            <p className='text-midnightAbyss h3 font-semibold mt-4'>TV</p>
                                        </div>
                                        <div className="group w-1/3 h-full bg-cover bg-no-repeat relative">
                                            <div className="absolute inset-0 transition-theme opacity-0 group-hover:opacity-100">
                                                <img src={PcOn} alt="TV On" className="w-full h-[240px] object-contain" />
                                            </div>
                                            <img src={PcOff} alt="TV Off" className="w-full h-[240px] object-contain" />
                                            <p className='text-midnightAbyss h3 font-semibold mt-4'>Komputer</p>
                                        </div>
                                        <div className="group w-1/3 h-full bg-cover bg-no-repeat relative">
                                            <div className="absolute inset-0 transition-theme opacity-0 group-hover:opacity-100">
                                                <img src={MobileOn} alt="TV On" className="w-full h-[240px] object-contain" />
                                            </div>
                                            <img src={MobileOff} alt="TV Off" className="w-full h-[240px] object-contain" />
                                            <p className='text-midnightAbyss h3 font-semibold mt-4'>Mobile</p>
                                        </div>
                                    </div>
                                }
                                {isMobile &&
                                    <img srcSet={MobileScreensApp} alt="Dostępne ekrany urządzeń" />
                                } */}
                            </Column>
                        </Row>
                    </Container>
                    <div className="bg-[#E1E5F2]/70 py-24 maxMd:py-12 text-midnightAbyss">
                        <Container>
                            <Row className='justify-center'>
                                <Column xl={8} className='text-center'>
                                    <h6 className='h2 font-semibold mb-14 maxMd:mb-10'>Lorem ipsum dolor sit.</h6>
                                </Column>
                                <Column xl={8}>
                                    <AccordionItem heading='Lorem ipsum?' content='Lorem ipsum dolor sit amet consectetur adipisicing elit. Non maxime optio porro autem quis natus corporis amet. Ab sapiente magnam dolores neque tempore, quibusdam doloribus necessitatibus consectetur quis perferendis modi voluptas facere sint alias tenetur quam ut saepe voluptatum unde repellendus delectus, voluptatibus, soluta rerum commodi. Maiores obcaecati earum illo.' />
                                    <AccordionItem heading='Lorem ipsum?' content='Lorem ipsum dolor sit amet consectetur adipisicing elit. Non maxime optio porro autem quis natus corporis amet. Ab sapiente magnam dolores neque tempore, quibusdam doloribus necessitatibus consectetur quis perferendis modi voluptas facere sint alias tenetur quam ut saepe voluptatum unde repellendus delectus, voluptatibus, soluta rerum commodi. Maiores obcaecati earum illo.' />
                                    <AccordionItem heading='Lorem ipsum?' content='Lorem ipsum dolor sit amet consectetur adipisicing elit. Non maxime optio porro autem quis natus corporis amet. Ab sapiente magnam dolores neque tempore, quibusdam doloribus necessitatibus consectetur quis perferendis modi voluptas facere sint alias tenetur quam ut saepe voluptatum unde repellendus delectus, voluptatibus, soluta rerum commodi. Maiores obcaecati earum illo.' />
                                    <AccordionItem heading='Lorem ipsum?' content='Lorem ipsum dolor sit amet consectetur adipisicing elit. Non maxime optio porro autem quis natus corporis amet. Ab sapiente magnam dolores neque tempore, quibusdam doloribus necessitatibus consectetur quis perferendis modi voluptas facere sint alias tenetur quam ut saepe voluptatum unde repellendus delectus, voluptatibus, soluta rerum commodi. Maiores obcaecati earum illo.' />
                                    <AccordionItem heading='Lorem ipsum?' content='Lorem ipsum dolor sit amet consectetur adipisicing elit. Non maxime optio porro autem quis natus corporis amet. Ab sapiente magnam dolores neque tempore, quibusdam doloribus necessitatibus consectetur quis perferendis modi voluptas facere sint alias tenetur quam ut saepe voluptatum unde repellendus delectus, voluptatibus, soluta rerum commodi. Maiores obcaecati earum illo.' />
                                </Column>
                            </Row>
                        </Container>
                    </div>
                </main>


                <footer className='px-14 maxMd:py-4 flex flex-wrap maxMd:gap-y-4 justify-center z-20 relative py-5 items-center gap-x-10 mt-auto'>
                    <a href='#' target='_blank' title='Polityka prywatności' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Polityka prywatności
                    </a>
                    <a href='#' target='_blank' title='Regulamin serwisu' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Regulamin serwisu
                    </a>
                    <Link href={route('front.help')} title='Pomoc' className='text-royalPeriwinkle text-16 leading-6 tracking-[0.24px] maxMd:w-full maxMd:text-center'>
                        Pomoc
                    </Link>
                </footer>
            </div>
        </>
    );
}

export default Index;